import React, { PureComponent } from 'react';
import cookie from 'react-cookies';
import { EPAAS_SESSION_STATE_CHANGE } from '../const';
import { safeRedirect } from '../utils/urlValidator';

class ThreeLogin extends PureComponent {
  constructor(props) {
    super(props);
    let testEnv = this.props.env === 'test' || this.props.env === 'local';
    this.erpUrl = testEnv ? 'http://test.ssa.jd.com/sso/login' : 'http://ssa.jd.com/sso/login';
    this.erpOverseasUrl = testEnv ? 'http://test.ssa.jd.com/sso/login' : 'http://hkssa.jd.com/sso/login';
    this.pinUrl = 'https://passport.jd.com/new/login.aspx';
  }

  redirectToSso(url) {
    cookie.save('jd.erp.lang', this.props.chinese ? 'zh_CN' : 'en_US', {
      domain: '.jd.com',
      path: '/',
    });
    try {
      window.localStorage.setItem(EPAAS_SESSION_STATE_CHANGE, `login_success_${Date.now()}`);
    } catch (error) {
      console.error('EPAAS_SESSION_STATE_CHANGE', error);
    }
    safeRedirect(url);
  }

  redirectToJdMall(url) {
    safeRedirect(url);
  }

  getErpCompleteUrl() {
    let returnUrlSuffix = '?ReturnUrl=' + escape(this.props.userReturnUrl) + '&clientId=' + this.props.clientId + '&lang=' + (this.props.chinese ? 'zh_CN' : 'en_US');
    let serverErpReturnUrl = this.props.baseServerPath + '/erp' + returnUrlSuffix;
    return (this.props.chinese ? this.erpUrl : this.erpOverseasUrl) + '?ReturnUrl=' + escape(serverErpReturnUrl);
  }

  goRedirectToSso() {
    this.redirectToSso(this.getErpCompleteUrl());
  }

  render() {
    const { scene } = this.props;
    let threeLoginBoxStyle = {
      display: this.props.frontType === 'ALL' ? 'flex' : 'none',
      height: 36,
      fontSize: 14,
      fontWeight: 400,
      color: '#333',
      whiteSpace: 'pre-wrap',
      lineHeight: 2,
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      border: '1px solid rgba(217,217,217,1)',
      borderRadius: '4px',
      cursor: 'pointer',
    };

    let iconStyle = {
      width: 30,
      height: 30,
      marginRight: 4,
      float: 'left',
    };

    let aStyle = {
      color: '#333',
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
    };

    let erpStyle = {
      display: this.props.frontType ? 'flex' : 'none',
    };

    let aBoxStyle = {
      textAlign: 'center',
      cursor: 'pointer',
    };

    let explainBoxStyle = {
      float: 'left',
    };

    let erpIconStyle = {
      width: 24,
      height: 24,
      borderRadius: '50%',
      color: '#fff',
      backgroundColor: '#FE3E33',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '8px',
    };

    let returnUrlSuffix = '?ReturnUrl=' + escape(this.props.userReturnUrl) + '&clientId=' + this.props.clientId + '&lang=' + (this.props.chinese ? 'zh_CN' : 'en_US');

    let serverPinReturnUrl = this.props.baseServerPath + '/pin' + returnUrlSuffix;
    // let serverErpReturnUrl = this.props.baseServerPath + '/erp' + returnUrlSuffix;
    let pinCompleteUrl = this.pinUrl + '?ReturnUrl=' + escape(serverPinReturnUrl);
    // let erpCompleteUrl = (this.props.chinese ? this.erpUrl : this.erpOverseasUrl) + '?ReturnUrl=' + escape(serverErpReturnUrl);
    
    if (scene === 'three') {
      return (
        <div style={threeLoginBoxStyle} className="jd-account-login" onClick={() => this.redirectToJdMall(pinCompleteUrl)}>
          <img style={iconStyle} src="https://storage.jd.com/focus-login-web/esuite-static/image/login_jd_com_site_logo.png" alt="jd joy"></img>
          <div style={explainBoxStyle}>{this.props.chinese ? '京东账号登录' : 'JD Account login'}</div>
        </div>
      );
    }
    
    return (
      <div style={erpStyle}>
        <div style={aBoxStyle}>
          <div style={aStyle} onClick={() => this.goRedirectToSso()} className="erp-account-login">
            <img style={erpIconStyle} src="https://storage.jd.com/focus-login-web/esuite-static/image/login_erp_site_logo.png" alt="jd erp"></img>
            <span className="erp-text">{this.props.chinese ? 'ERP账号登录' : 'Log in with ERP account'}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default React.forwardRef((props, ref) => {
  return <ThreeLogin {...props} ref={ref} />;
});
