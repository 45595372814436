import React, { PureComponent } from 'react';
import QRCodeBox from './QRCodeBox';
import ThreeLogin from './ThreeLogin';
import { Tag } from 'antd';

export default class LoginBox extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      subTitleHeight: 32,
      subTitleFontDisplay: 'block',
      comfirmTipHeight: 0,
      comfirmTipFontDisplay: 'none',
      comfirmTipText: '',
      frontType: '',
    };
  }

  onWaitComfirm = () => {
    this.setState(
      {
        subTitleHeight: 0,
        subTitleFontDisplay: 'none',
        comfirmTipHeight: 32,
      },
      () => {
        let tip = this.props.chinese ? '扫描成功，请在你的手机上确认' : 'Scanned successfully, confirm on your mobile.';
        setTimeout(() => {
          this.setState({
            comfirmTipFontDisplay: 'block',
            comfirmTipText: tip,
          });
        }, 500);
      }
    );
  };

  onWaitRedirect = () => {
    let tip = this.props.chinese ? '授权成功，即将跳转' : 'Authorization successful.';
    this.setState({ comfirmTipText: tip });
  };

  onExpire = () => {
    this.setState(
      {
        comfirmTipHeight: 0,
        comfirmTipFontDisplay: 'none',
        subTitleHeight: 32,
      },
      () => {
        setTimeout(() => {
          this.setState({
            subTitleFontDisplay: 'block',
          });
        }, 500);
      }
    );
  };

  onFrontTypeChange = (frontType) => {
    this.setState({ frontType: frontType });
  };

  onSafeCheck = () => {
    console.log('onSafeCheck', this.ssoThreeLoginRef);
    if (this.ssoThreeLoginRef?.goRedirectToSso) {
      this.ssoThreeLoginRef.goRedirectToSso();
    }
  };

  render() {
    let qrSize = 180;
    let loginBoxWidth = 360;
    let loginBoxPadding = 20;

    let loginBoxStyle = {
      boxSizing: 'content-box',
      position: 'relative',
      width: loginBoxWidth,
      textAlign: 'center',
      padding: loginBoxPadding,
      fontFamily: 'helvetica neue,Tahoma,PingFangSC-Regular,Microsoft Yahei,Myriad Pro,Hiragino Sans GB,sans-serif',
    };

    let loginWrapStyle = {
      width: '360px',
      minHeight: '396px',
      backgroundColor: 'rgba(255,255,255,1)',
      border: '1px solid rgba(230,230,230,1)',
      borderRadius: '8px',
      boxShadow: '0 2px 12px 0 rgba(0,0,0,0.1)',
    };

    let envTagStyle = {
      display: this.props.env === 'test' ? 'block' : 'none',
      marginTop: 3,
      marginLeft: 8,
    };

    let qrStyle = {
      boxSizing: 'content-box',
      width: qrSize,
      height: qrSize,
      margin: (loginBoxWidth - qrSize) / 2,
      marginBottom: 0,
      marginTop: 0,
    };

    let titleStyle = {
      color: '#333',
      fontSize: 20,
      fontWeight: 600,
      lineHeight: 2,
    };

    let flagWrapStyle = {
      height: 46,
      display: 'flex',
      alignItems: 'center',
    };

    let flagStyle = {
      height: 24,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0 8px',
      backgroundColor: '#FEEBEA',
      borderRadius: '0 4px 4px 0',
      color: 'rgba(254,62,51,1)',
      fontSize: '12px',
      marginLeft: '-0.5px',
    };

    let subTitleStyle = {
      boxSizing: 'content-box',
      //height: this.state.subTitleHeight,
      paddingBottom: 16,
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 2,
      color: '#666',
      whiteSpace: 'pre-wrap',
      transition: 'height 0.5s',
    };

    let comfirmTipDisplay = {
      height: this.state.comfirmTipHeight,
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 2,
      whiteSpace: 'pre-wrap',
      transition: 'height 0.5s',
    };

    let separateBoxSytle = {
      height: 30,
      fontSize: 12,
      fontWeight: 400,
      color: '#666',
      whiteSpace: 'pre-wrap',
      display: this.state.frontType === 'ALL' ? 'flex' : 'none',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 33,
    };

    let separateLineStyle = {
      height: 1,
      width: loginBoxWidth / 2,
      margin: '0 10px 0 0',
      backgroundColor: '#E6E6E6',
    };

    let separateRLineStyle = {
      margin: '0 0 0 10px',
      height: 1,
      width: loginBoxWidth / 2,
      backgroundColor: '#E6E6E6',
    };

    let separateTextStyle = {
      flexShrink: 0,
    };

    let threeLoginStyle = {
      width: loginBoxWidth,
      textAlign: 'left',
      padding: '10px 0',
    };

    let erpLoginStyle = {
      height: 90,
      display: 'flex',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
    };

    const qrcodeUrl = this.props.chinese ? '/get/ssoQrcode' : '/get/qrcode';

    return (
      <div style={loginBoxStyle}>
        <div style={loginWrapStyle}>
          <div style={flagWrapStyle}>
            <div style={flagStyle}>{this.props.chinese ? '京东员工' : 'JD employees'}</div>
          </div>
          <div style={titleStyle}>{this.props.chinese ? '扫码登录' : 'Scan QR Code'}</div>
          <div style={subTitleStyle}>
            <div style={{ display: this.state.subTitleFontDisplay }}>{this.props.chinese ? '请使用京东ME扫码' : 'Please use JDME to scan QR code and login'}</div>
            <div style={envTagStyle}>
              <Tag color="error">{this.props.chinese ? '测试环境' : 'TEST Environment'}</Tag>
            </div>
          </div>
          <div style={qrStyle}>
            <QRCodeBox
              chinese={this.props.chinese}
              getQrCodeUrl={this.props.baseServerPath + qrcodeUrl}
              businessReturnUrl={this.props.businessReturnUrl}
              qrSize={qrSize}
              clientId={this.props.clientId}
              onWaitComfirm={this.onWaitComfirm}
              onWaitRedirect={this.onWaitRedirect}
              onExpire={this.onExpire}
              onFrontTypeChange={this.onFrontTypeChange}
              onSafeCheck={this.onSafeCheck}
            ></QRCodeBox>
          </div>

          <div style={erpLoginStyle}>
            <ThreeLogin
              chinese={this.props.chinese}
              ref={(ref) => (this.ssoThreeLoginRef = ref)}
              env={this.props.env}
              userReturnUrl={this.props.businessReturnUrl}
              baseServerPath={this.props.baseServerPath}
              frontType={this.state.frontType}
              clientId={this.props.clientId}
            ></ThreeLogin>
          </div>
        </div>

        <div style={comfirmTipDisplay}>
          <div style={{ display: this.state.comfirmTipFontDisplay }}>{this.state.comfirmTipText}</div>
        </div>
        <div>
          <div style={separateBoxSytle}>
            <div style={separateLineStyle}></div>
            <div style={separateTextStyle}>{this.props.chinese ? '外部商家或合作伙伴' : 'External merchants or partners'}</div>
            <div style={separateRLineStyle}></div>
          </div>
          <div style={threeLoginStyle}>
            <ThreeLogin
              chinese={this.props.chinese}
              env={this.props.env}
              userReturnUrl={this.props.businessReturnUrl}
              baseServerPath={this.props.baseServerPath}
              frontType={this.state.frontType}
              clientId={this.props.clientId}
              scene="three"
            ></ThreeLogin>
          </div>
        </div>
      </div>
    );
  }
}
