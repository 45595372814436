export const isValidUrl = (url) => {
  try {
    const parsedUrl = new URL(url);
    // 只允许 http 和 https 协议 并且 host 以 jd.com 结尾
    return ['http:', 'https:'].includes(parsedUrl.protocol) && parsedUrl.host.endsWith('jd.com');
  } catch (e) {
    return false;
  }
};

export const safeRedirect = (url) => {
  if (!isValidUrl(url)) {
    console.error('Invalid redirect URL detected');
    return false;
  }
  window.location.assign(url);
  return true;
}; 