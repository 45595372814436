import React, { PureComponent } from 'react';
import LoginBox from './LoginBox';
import cookie from 'react-cookies';
import Setting from '../Setting';

import { Menu, Dropdown } from 'antd';
import { createFromIconfontCN } from '@ant-design/icons';
import './../index.css';

/**
 * 本地实现判断是否在jme内部
 * @returns
 */
function isInNative() {
  // @ts-ignore
  return window._dsbridge || window._dswk || navigator.userAgent.indexOf('_dsbridge') !== -1;
}

function IsMobile() {
  try {
    const rs = isInNative();
    if (rs) {
      return true;
    }
  } catch (error) {}
  return /Android|webOS|iPhone|iPod|iPad|BlackBerry|JDME| ME\//i.test(navigator.userAgent);
}

const isMobile = IsMobile();
// 桌面端
const ISMEDesktop = isMobile && /Windows|Linux|Macintosh/i.test(navigator.userAgent);

const IconFont = createFromIconfontCN({
  scriptUrl: 'https://s2-relay.360buyimg.com/relay/c/iconfont/4/font_269363W2qfwd0OYKH7.js',
});

export default class LoginPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      chinese: true,
      langOrigin: '',
      clientId: '',
      businessReturnUrl: '',
      leftImageDisplay: 'block',
      languageDropOpen: false,
    };
    this.baseServerPath = Setting('baseServerPath');
    this.env = Setting('env');
  }

  componentWillMount() {
    let search = this.props.location.search.replace('?', '');
    let queryArray = search.split('&');
    if (queryArray.length === 0) {
      return;
    }
    let returnUrl;
    let clientId;
    let lang = 'zh_CN';
    for (let i = 0; i < queryArray.length; i++) {
      let paramArray = queryArray[i].split('=');
      if (paramArray.length < 2) {
        continue;
      }
      switch (paramArray[0]) {
        case 'ReturnUrl':
          returnUrl = unescape(paramArray[1]);
          // inner 转发到https
          if (returnUrl.indexOf('https://joyspace-inner.jd.com') === 0) {
            returnUrl = returnUrl.replace('https://joyspace-inner.jd.com', 'http://joyspace-inner.jd.com');
          }
          this.setState({ businessReturnUrl: returnUrl });
          break;
        case 'lang':
          lang = paramArray[1];
          this.changeLanguage({ key: lang });
          this.setState({ langOrigin: 'path' });
          break;
        case 'clientId':
          clientId = paramArray[1];
          this.setState({ clientId });
          break;
        default:
          break;
      }
    }
    if (!this.state.langOrigin) {
      let lang = cookie.load('focus-lang');
      if (lang) {
        this.setState({
          chinese: lang === 'zh_CN',
          langOrigin: 'cookie',
        });
      }
    }
    // 在京东ME桌面端切换到内网
    if (ISMEDesktop) {
      const url = Setting('baseServerPath') + '/erp?ReturnUrl=' + encodeURIComponent(returnUrl) + '&clientId=' + clientId + '&lang=' + lang;
      window.location.href = `http://autherp.jd.com?returnUrl=${encodeURIComponent(url)}`;
    }
  }

  componentDidMount() {
    this.setLeftImageDisplay();
    window.addEventListener('resize', this.setLeftImageDisplay);
  }

  setLeftImageDisplay = () => {
    if (document.querySelector('body').offsetWidth <= 916) {
      this.setState({ leftImageDisplay: 'none' });
    } else {
      this.setState({ leftImageDisplay: 'block' });
    }
  };

  changeLanguage = ({ key }) => {
    if (key === 'en_US') {
      this.setState({ chinese: false });
      cookie.save('focus-lang', 'en_US', { domain: '.jd.com', path: '/' });
    } else {
      this.setState({ chinese: true });
      cookie.save('focus-lang', 'zh_CN', { domain: '.jd.com', path: '/' });
    }
  };

  render() {
    let loginPageStyle = {
      width: '100%',
      height: '100%',
      position: 'absolute',
      left: 0,
      top: 0,
      fontFamily: 'helvetica neue,Tahoma,PingFangSC-Regular,Microsoft Yahei,Myriad Pro,Hiragino Sans GB,sans-serif',
    };

    let titleStyle = {
      fontSize: 28,
      fontWeight: 500,
      lineHeight: 2,
      color: '#646a73',
      whiteSpace: 'pre-wrap',
      paddingLeft: 80,
      paddingTop: 30,
    };

    let logoStyle = {
      width: 120,
    };

    let subTitleStyle = {
      marginTop: this.state.chinese ? 200 : 150,
      marginLeft: 80,
      width: 357,
      opacity: 0.55,
      fontSize: 22,
      color: '#2F3742',
      lineHeight: '38px',
    };

    let leftImageStyle = {
      width: 516,
      height: '100%',
      backgroundSize: '100%',
      backgroundImage: 'url(https://storage.360buyimg.com/focus.public/images_login_illustration.jpg)',
      float: 'left',
      display: this.state.leftImageDisplay,
    };

    let loginBoxStyle = {
      height: '100%',
      display: 'flex',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    };

    let languageDropStyle = {
      position: 'absolute',
      right: '5%',
      top: '5%',
      cursor: 'pointer',
    };

    let menuItemStyle = {
      height: 36,
      display: 'flex',
      alignItems: 'center',
      color: '#333',
    };
    // 桌面端环境
    if (ISMEDesktop) {
      return null;
    }
    // 移动端使用
    if (isMobile) {
      return (
        <div className="login-erp-jd-common-base-page mb-login-guide">
          <img className="mb-guide-img" src="https://storage.jd.com/focus-login-web/esuite-static/image/esuite_login_guide_for_mobile.png" alt="mb guide"></img>
          <div>{this.state.chinese ? '请在电脑浏览器中打开链接' : 'Please open the link in PC browser'}</div>
        </div>
      );
    }

    return (
      <div style={loginPageStyle} className="login-erp-jd-common-base-page">
        <div style={leftImageStyle}>
          <div style={titleStyle}>
            <img style={logoStyle} src="https://storage.360buyimg.com/focus.public/joyspace_logo.png" alt="JoySpace" />
          </div>
          <div style={subTitleStyle}>
            {this.state.chinese
              ? 'JoySpace 为您带来了前所未有的文档协作体验，让协作变得更加顺畅和触手可得。'
              : 'JoySpace brings you an unprecedented experience of document collaboration, making collaboration smoother and more accessible.'}
          </div>
        </div>
        <div style={languageDropStyle}>
          <Dropdown
            placement="bottomRight"
            overlayStyle={{ width: 164, height: 36 }}
            onVisibleChange={(open) => {
              this.setState({ languageDropOpen: open });
            }}
            overlay={
              <Menu onClick={this.changeLanguage}>
                <Menu.Item key="zh_CN">
                  <div style={menuItemStyle}>
                    简体中文 <span style={{ flex: 1 }} />
                    {this.state.chinese && <IconFont type="icon-prompt-check" style={{ color: '#FE3E33' }} />}
                  </div>
                </Menu.Item>
                <Menu.Item key="en_US">
                  <div style={menuItemStyle}>
                    English
                    <span style={{ flex: 1 }} />
                    {!this.state.chinese && <IconFont type="icon-prompt-check" style={{ color: '#FE3E33' }} />}
                  </div>
                </Menu.Item>
              </Menu>
            }
          >
            <div className={`choice-language-btn ${this.state.languageDropOpen ? 'choice-language-btn-active' : ''}`} onClick={(e) => e.preventDefault()}>
              <IconFont type="icon-general-global" style={{ marginRight: 4 }} />
              {this.state.chinese ? '简体中文' : 'English'}
              <IconFont type="icon-padding-caredown" style={{ marginLeft: 6, fontSize: 6, color: '#8F959E' }} />
            </div>
          </Dropdown>
        </div>
        <div style={loginBoxStyle}>
          <LoginBox
            clientId={this.state.clientId}
            businessReturnUrl={this.state.businessReturnUrl}
          chinese={this.state.chinese}
            baseServerPath={this.baseServerPath}
            env={this.env}
          ></LoginBox>
        </div>
      </div>
    );
  }
}
