import React, { Component } from 'react';
import QRCode from 'qrcode.react';
import cookie from 'react-cookies';
import getBaseServerPath from '../Setting';
import {
  JANUS_HOST_PROP_NAME,
  JANUS_APPID_PROP_NAME,
  JANUS_STAGE_PROP_NAME,
  MAX_CHECK_MOUNT,
  EPAAS_SESSION_STATE_CHANGE,
} from '../const';
import { safeRedirect } from '../utils/urlValidator';
import { Modal } from 'antd';

export default class QRCodeBox extends Component {
  interval = {};
  timeout = {};
  checkTimer = null;
  checkCounter = 0;
  checking = false;

  constructor(props) {
    super(props);
    this.state = {
      qrCodeDisplay: 'none',
      refreshDisplay: 'none',
      waitConfirmDisplay: 'none',
      qrColor: 'black',
      successRoundColor: '#df443b',
      appName: '',
      qrCodeUrl: '',
      pollingUrl: '',
      expire: false,
      waitingConfirm: false,
      waitingRedirect: false,
      waitScanExpire: 120,
      waitConfirmExpire: 30,
    };
  }

  componentDidMount() {
    this.requestNewQrCode(this.props.getQrCodeUrl, () => {
      this.setState({ qrCodeDisplay: 'block' });
      this.timeout = setTimeout(this.handleExpire, this.state.waitScanExpire * 1000);
      this.interval = setInterval(this.pollingUrlQrCodeStatus, 1000);
    });
    // 检查登录态
    this.handleAuthStatusCheck();
    // 页面切换
    document.addEventListener('visibilitychange', this.handlePageVisibleChange);
    window.addEventListener('storage', this.handleLoginSuccessedStatusChange);
  }

  componentWillUnmount() {
    document.removeEventListener('visibilitychange', this.handlePageVisibleChange);
    document.removeEventListener('storage', this.handleLoginSuccessedStatusChange);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.getQrCodeUrl !== nextProps.getQrCodeUrl) {
      this.setState({
        qrCodeDisplay: 'none',
        refreshDisplay: 'none',
        waitConfirmDisplay: 'none',
        qrColor: 'black',
        successRoundColor: '#df443b',
        appName: '',
        qrCodeUrl: '',
        pollingUrl: '',
        expire: false,
        waitingConfirm: false,
        waitingRedirect: false,
        waitScanExpire: 120,
        waitConfirmExpire: 30,
      });
      clearTimeout(this.timeout);
      clearInterval(this.interval);
      this.requestNewQrCode(nextProps.getQrCodeUrl, () => {
        this.setState({ qrCodeDisplay: 'block' });
        this.timeout = setTimeout(this.handleExpire, this.state.waitScanExpire * 1000);
        this.interval = setInterval(this.pollingUrlQrCodeStatus, 1000);
      });
    }
    return true;
  }

  requestNewQrCode = (getQrCodeUrlStr, callback) => {
    let getQrCodeUrl =
      getQrCodeUrlStr +
      '?ReturnUrl=' +
      escape(this.props.businessReturnUrl) +
      '&clientId=' +
      this.props.clientId;
    fetch(getQrCodeUrl, {
      method: 'GET',
      mode: 'cors',
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.errorCode === '0') {
          this.setState({
            appName: data.content.appName,
            qrCodeUrl: data.content.qrCodeUrl,
            pollingUrl: data.content.pollingUrl,
            waitScanExpire: data.content.waitScanExpire,
            waitConfirmExpire: data.content.waitConfirmExpire,
          });
          this.props.onFrontTypeChange(data.content.frontType);
          if (callback) {
            callback();
          }
        } else if (data.errorCode === '1008') {
          alert(this.props.chinese ? '应用认证失败！' : 'Application authentication failed!');
        } else {
          alert('请求二维码失败!');
          console.error(data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  handleSafeCheckSid = null;

  // 安全检查，登录二验
  handleSafeCheck = () => {
    if (this.handleSafeCheckSid) {
      clearTimeout(this.handleSafeCheckSid);
    }
    this.handleSafeCheckSid = setTimeout(() => {
      Modal.confirm({
        title: this.props.chinese ? '请输入账号密码登录京ME' : 'Verification Required',
        content: this.props.chinese ? '根据信息安全和账号安全要求验证账密。如有疑问,可咨询信息安全:账号小妹xnmisspin3' : 'Due to account security requirements, you need to verify your ERP account to login.If you have any questions, please consult the information security staff: xnmisspin3',
        okText: this.props.chinese ? '去验证' : 'Verify',
        cancelText: this.props.chinese ? '取消' : 'Cancel',
        okButtonProps: {
          style: {
            backgroundColor: '#FE3E33',
            borderColor: '#FE3E33',
          },
        },
        cancelButtonProps: {
          type: 'default',
        },
        onOk: () => {
          if (this.props.onSafeCheck) {
            this.props.onSafeCheck();
          }
        },
        onCancel: () => {
          this.handleExpire();
        },
      });
    }, 150);
  };

  pollingUrlQrCodeStatus = (callback) => {
    fetch(this.state.pollingUrl + '?clientId=' + this.props.clientId, {
      method: 'GET',
      mode: 'cors',
    })
      .catch((e) => {
        console.log(e);
        clearInterval(this.interval);
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.errorCode === '0') {
          let status = data.content.qrStatus;
          if (status === 'EXPIRED') {
            this.handleExpire();
          } else if (status === 'WAIT_CONFIRM') {
            this.handleWaitConfirm();
          } else if (status === 'WAIT_REDIRECT') {
            clearTimeout(this.timeout);
          } else if (status === 'REDIRECT') {
            let token = data.content.accessToken;
            let teamId = data.content.teamId;
            let returnUrl = data.content.returnUrl;
            if (teamId && returnUrl) {
              this.syncLoginSuccessedStatus();
              this.handleWaitRedirect();
              this.checkCounter = MAX_CHECK_MOUNT;
              setTimeout(() => {
                this.redirectToBussinessPage(token, teamId, returnUrl);
              }, 1000);
            } else {
              console.error(token, teamId, returnUrl);
              this.handleExpire();
            }
          } else if (status === 'SAFE_CHECK') {
            this.handleSafeCheck();
            clearInterval(this.interval);
          }
          if (callback) {
            callback();
          }
        } else {
          console.error(data);
        }
      });
  };

  redirectToBussinessPage = (token, teamId, returnUrl) => {
    const decodedUrl = decodeURIComponent(returnUrl);
    // 先设置 cookie
    token && cookie.save('focus-token', token, { domain: '.jd.com', path: '/' });
    cookie.save('focus-team-id', teamId, { domain: '.jd.com', path: '/' });
    cookie.save('focus-lang', this.props.chinese ? 'zh_CN' : 'en_US', {
      domain: '.jd.com',
      path: '/',
    });

    // 然后进行安全重定向
    safeRedirect(decodedUrl);
  };

  handleWaitConfirm = () => {
    if (this.state.waitingConfirm) {
      return;
    }
    this.setState(
      {
        waitConfirmDisplay: 'flex',
        qrColor: 'rgba(0,0,0,.3)',
        waitingConfirm: true,
      },
      () => {
        clearTimeout(this.timeout);
        this.props.onWaitComfirm();
        this.timeout = setTimeout(() => {
          this.handleExpire();
        }, this.state.waitConfirmExpire * 1000);
      }
    );
  };

  handleWaitRedirect = () => {
    if (this.state.waitingRedirect) {
      return;
    }
    this.setState(
      {
        waitingRedirect: true,
        successRoundColor: '#63d7b6',
      },
      () => {
        clearTimeout(this.timeout);
        this.props.onWaitRedirect();
        this.timeout = setTimeout(() => {
          this.handleExpire();
        }, this.state.waitConfirmExpire * 1000);
      }
    );
  };

  handleExpire = () => {
    if (this.state.expire) {
      return;
    }
    this.setState(
      {
        refreshDisplay: 'flex',
        waitConfirmDisplay: 'none',
        qrColor: 'black',
        expire: true,
      },
      () => {
        clearInterval(this.interval);
        this.props.onExpire();
      }
    );
  };

  refreshQRCode = () => {
    this.requestNewQrCode(this.props.getQrCodeUrl, () => {
      this.setState(
        {
          qrUrl: this.state.qrCodeUrl,
          refreshDisplay: 'none',
          waitingConfirm: false,
          expire: false,
        },
        () => {
          this.timeout = setTimeout(this.handleExpire, this.state.waitScanExpire * 1000);
          this.interval = setInterval(this.pollingUrlQrCodeStatus, 1000);
        }
      );
    });
  };
  // 检查登陆态
  handleAuthStatusCheck = async () => {
    // 清理内容
    if (this.checkTimer) {
      clearTimeout(this.checkTimer);
      this.checkTimer = null;
    }
    // 超过1W 直接停止检查 400000
    if (this.checkCounter > MAX_CHECK_MOUNT || this.checking) {
      return;
    }
    this.checking = true;
    const focusToken = cookie.load('focus-token', true);
    const focusTeamId = cookie.load('focus-team-id', true);
    const focusLang = cookie.load('focus-lang', true);
    // // 存在focus-token,验证epaas 登录
    // if (focusToken) {
    //# 获取用户信息
    try {
      const res = await fetch(getBaseServerPath(JANUS_HOST_PROP_NAME) + '/login.getUserProfile', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Api': 'login.getUserProfile',
          'X-App': getBaseServerPath(JANUS_APPID_PROP_NAME),
          'X-Gw-Version': '2',
          'X-Stage': getBaseServerPath(JANUS_STAGE_PROP_NAME),
          'X-Team-Id': focusTeamId || '',
          'X-Token': focusToken || '123',
          'X-Client': 'WEB',
          'X-Ts': Date.now(),
          'X-Lang': focusLang || 'zh_CN',
          'X-Nonce': Date.now().toString(26),
        },
        credentials: 'include',
        body: JSON.stringify({}),
      }).then((x) => x.json());
      if (res.errorCode === '0' && res.content) {
        this.syncLoginSuccessedStatus();
        safeRedirect(this.props.businessReturnUrl);
        return;
      }
      // 删除cookie
      cookie.remove('focus-token');
      cookie.remove('focus-team-id');
      cookie.remove('focus-lang');
    } catch (error) {
      // 忽略认证失败消息
    }
    // }
    //获取sso登录信息
    try {
      // await new Promise((resolve, reject) => {
      const url =
        getBaseServerPath('baseServerPath') +
        '/erp?ReturnUrl=' +
        escape(this.props.businessReturnUrl) +
        '&clientId=' +
        this.props.clientId +
        '&lang=' +
        (this.props.chinese ? 'zh_CN' : 'en_US');
      await fetch(url, { redirect: 'manual', credentials: 'include' });
    } catch (error) {
      // 忽略认证失败消息
    }
    this.checkCounter = this.checkCounter + 1;
    // 下一次轮训
    this.checkTimer = setTimeout(this.handleAuthStatusCheck, 2000);
    //重置标识为
    this.checking = false;
  };

  tryToNextCheck = () => {
    if (this.checkCounter >= MAX_CHECK_MOUNT) {
      this.checkCounter = 0;
    }
    this.handleAuthStatusCheck();
  };

  handlePageVisibleChange = () => {
    // 页面tab 活跃时再次切换
    if (document.visibilityState && document.visibilityState === 'visible') {
      this.tryToNextCheck();
    }
  };
  // 处理同步事件
  handleLoginSuccessedStatusChange = (storageEvent) => {
    // 页面tab 活跃时再次切换
    try {
      if (EPAAS_SESSION_STATE_CHANGE === storageEvent.key) {
        this.tryToNextCheck();
      }
    } catch (err) {}
  };

  // 同步登陆成功状态
  syncLoginSuccessedStatus = () => {
    try {
      window.localStorage.setItem(EPAAS_SESSION_STATE_CHANGE, `login_success_${Date.now()}`);
    } catch (error) {
      console.error('EPAAS_SESSION_STATE_CHANGE', error);
    }
  };

  render() {
    let boxSize = this.props.boxSize ? this.props.boxSize : 180;

    let codeStyle = {
      width: boxSize,
      height: boxSize,
      position: 'absolute',
      display: this.state.qrCodeDisplay,
    };

    let refreshStyle = {
      width: boxSize,
      height: boxSize,
      position: 'absolute',
      backgroundColor: 'rgba(0,0,0,.6)',
      fontSize: 15,
      color: '#fff',
      cursor: 'pointer',
      zIndex: 2,
      display: this.state.refreshDisplay,
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    };

    let waitConfirmStyle = {
      width: boxSize,
      height: boxSize,
      position: 'absolute',
      backgroundColor: 'rgba(255,255,255,0)',
      fontSize: 15,
      color: '#a9e97a',
      zIndex: 1,
      display: this.state.waitConfirmDisplay,
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    };

    let refreshIconStyle = {
      width: 32,
      height: 34,
      marginBottom: 20,
    };

    let successIconStyle = {
      width: 50,
      height: 50,
    };

    let successRoundStyle = {
      width: 80,
      height: 80,
      backgroundColor: this.state.successRoundColor,
      borderRadius: 40,
      display: 'flex',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      transition: 'background-color 0.5s',
    };

    return (
      <div>
        <div style={codeStyle}>
          <QRCode
            value={this.state.qrCodeUrl + '&lang=' + (this.props.chinese ? 'zh_CN' : 'en_US')}
            size={boxSize}
            fgColor={this.state.qrColor}
          ></QRCode>
        </div>
        <div style={refreshStyle} onClick={this.refreshQRCode}>
          <svg
            style={refreshIconStyle}
            t="1585219015973"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="2558"
          >
            <path
              d="M976 384H784c-26.4 0-48-21.6-48-48s21.6-48 48-48h78.4C788.8 172.8 659.2 96 512 96 282.4 96 96 282.4 96 512s186.4 416 416
                    416c224 0 407.2-177.6 415.2-400h0.8c0-26.4 21.6-48 48-48s48 21.6 48 48c0 3.2 0 6.4-0.8 8.8C1010.4 808 786.4 1024 512 1024 229.6 1024
                    0 794.4 0 512S229.6 0 512 0c171.2 0 323.2 84 416 213.6V144c0-26.4 21.6-48 48-48s48 21.6 48 48v192c0 26.4-21.6 48-48 48z"
              p-id="2559"
              fill="#ffffff"
            ></path>
          </svg>
          {this.props.chinese ? '刷新二维码' : 'Refresh QR Code'}
        </div>
        <div style={waitConfirmStyle}>
          <div style={successRoundStyle}>
            <img
              style={successIconStyle}
              src="https://storage.360buyimg.com/focus.public/success.png"
              alt="扫描成功"
            />
          </div>
        </div>
      </div>
    );
  }
}
